header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.header__title {
  cursor: pointer;
  padding: 5px;
  border: 1px solid white;
  border-radius: 5px;
}

.header__title:hover {
  color: black;
  background-color: white;
}
.header__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.header__input {
  border: 0;
  background: none;
  border-bottom: 1px solid white;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 5px;
}

.header__input:focus {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.header__list li {
  padding: 5px;
  color: black;
  font-weight: normal;
}

.header__list li:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
}
