body {
  scroll-behavior: smooth;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
}

.container {
  max-width: 640px;
  margin: 0 auto;
}

.layout {
  padding: 1rem;
  margin: 0 auto;
  color: white;
  font-weight: bold;
}

.layout.day {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 191, 255, 1) 100%
  );
}

.layout.night {
  background-color: #2a2a35;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
