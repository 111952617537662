.forecast__current {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.forecast__current h1 {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.forecast__current h2 {
  font-size: 2rem;
  font-weight: normal;
}
