.header__refresh {
  cursor: pointer;
}
.header__refresh.active img {
  animation: spin 1s linear infinite;
}

.header__search {
  opacity: 0;
  position: absolute;
  top: 60px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  color: black;
}

.header__search.show {
  opacity: 1 !important;
}
